import { configureStore } from "@reduxjs/toolkit";
import portfolios from './features/portfolio/portfolioSclice'
import resume from './features/resume/resumeSlice'


const store = configureStore({
    reducer: {
        portfolios,
        resume
    }
})

export default store