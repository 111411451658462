import { createSlice, createAction } from '@reduxjs/toolkit';

const category = {
    redux: 'Redux',
    reactRouter: 'React Router',
    axios: 'Axios',
    materialUI: 'Material UI',
    another: 'Another'
}

const initialState = {
    projects: {
        1: {
            id: 1,
            name: 'Blog With Redux',
            category: category.redux,
            link: 'https://github.com/ahmadiweb/React-Simple-Blog',
            thumbnail: './GitHub-logo.jpg'
        },
        2: {
            id: 2,
            name: 'Slider With React',
            category: category.another,
            link: 'https://github.com/ahmadiweb/Slider-with-React',
            thumbnail: './GitHub-logo.jpg'
        },
        3: {
            id: 3,
            name: 'React World Map Clickable',
            category: category.another,
            link: 'https://github.com/ahmadiweb/React-Map-Clickable',
            thumbnail: './GitHub-logo.jpg'
        },
        4: {
            id: 4,
            name: 'Login Form',
            category: category.another,
            link: 'https://github.com/ahmadiweb/React-Login-Form',
            thumbnail: './GitHub-logo.jpg'
        }
    }
}

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addProject: (state, action) => {
            // اضافه کردن پروژه جدید به وضعیت
            const newProject = action.payload;
            state.projects[newProject.id] = newProject;
        }
    }
});

// ایجاد اکشن با استفاده از createAction
const addProject = createAction('projects/addProject');

const newProject = {
    id: 5,
    name: 'Todo list with filters',
    category: category.another,
    link: 'https://github.com/ahmadiweb/Todo-List-With-React-Redux',
    thumbnail: './GitHub-logo.jpg'
};

const action = addProject(newProject);
export const newState = projectsSlice.reducer(initialState, action);
