import { Outlet } from "react-router";

export default function Main({ children }) {
    return (
        <main className="page-wrapper-two">
            {children}
            <Outlet />
        </main>
    )
}
