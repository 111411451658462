export default function PortfolioCard({ data }) {
    const { id, name, category, link, thumbnail } = data
    return (
        <div
            key={id}
            data-aos="fade-up"
            data-aos-delay={100}
            data-aos-once="true"
            className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30 aos-init aos-animate"
        >
            <div
                className="rn-portfolio"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
            >
                <div className="inner">
                    <div className="thumbnail">
                        <a
                            href={link}
                            target="_blank"
                        >
                            <img
                                src={`./assest/portfolios/${thumbnail}`}
                                alt={name}
                                loading='lazy'
                            />
                        </a>
                    </div>
                    <div className="content">
                        <div className="category-info">
                            <div className="category-list">
                                <a href="#">{category}</a>
                            </div>
                            {/* <div className="meta">
                                <span>
                                    <a href="#">
                                        <i className="feather-heart" />
                                    </a>
                                    600
                                </span>
                            </div> */}
                        </div>
                        <h4 className="title">
                            <a href={link} target="_blank">
                                {name}{" "}
                                <i className="feather-arrow-up-right" />
                            </a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
