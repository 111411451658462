import { createSlice } from '@reduxjs/toolkit'

export const resumeTabs = {
    Education: 'Education',
    WorkExperience: 'Work experience',
    Skills: 'Skills',
    Languages: 'Languages'
}

const initialState = {
    Education: {
        1: {
            id: 1,
            grade: "Bachelor's",
            major: 'Computer Engineering',
            country: 'Iran',
            city: 'Tehran',
            university: 'Tehran Mallard University',
            entrance: '2020',
            graduation: '',
            studying: true
        },
    },
    WorkExperience: {
        1: {
            id: 1,
            position: 'Wordpress Website Designer',
            companyName: 'Techsima',
            country: 'Iran',
            city: 'Theran',
            startDate: '2019',
            startMonth: 'March',
            endDate: '2021',
            endMonth: 'March',
            workInThisCompany: false
        },
        2: {
            id: 2,
            position: 'Senior WordPress Website Designer',
            companyName: 'Zobdeganweb',
            country: 'Iran',
            city: 'Karaj',
            startDate: '2021',
            startMonth: 'March',
            endDate: '2021',
            endMonth: 'November',
            workInThisCompany: false
        },
        3: {
            id: 3,
            position: 'Senior WordPress Website Designer',
            companyName: 'Loomina',
            country: 'Iran',
            city: 'Karaj',
            startDate: '2021',
            startMonth: 'November',
            endDate: '2022',
            endMonth: 'August',
            workInThisCompany: false
        },
        4: {
            id: 4,
            position: 'Senior WordPress Website Designer',
            companyName: 'Derakhshansho',
            country: 'Iran',
            city: 'Karaj',
            startDate: '2022',
            startMonth: 'August',
            endDate: '2022',
            endMonth: 'August',
            workInThisCompany: true
        },
    },
    Skills: {
        1: {
            id: 1,
            name: 'React Router',
            level: '75%',
        },
        2: {
            id: 2,
            name: 'JSX',
            level: '100%',
        },
        3: {
            id: 3,
            name: 'Redux',
            level: '80%',
        },
        4: {
            id: 4,
            name: 'JavaScript Fundamental +ES6',
            level: '60%',
        },
        5: {
            id: 5,
            name: 'Functional Component Hooks',
            level: '70%',
        },
        6: {
            id: 6,
            name: ' lifecycle methods',
            level: '60%',
        },
        7: {
            id: 7,
            name: 'Conditional Rendering',
            level: '75%',
        },
        8: {
            id: 8,
            name: 'Familiarity with UI/UX concepts',
            level: '30%',
        },
        9: {
            id: 9,
            name: 'CSS, HTML',
            level: '100%',
        },
        10: {
            id: 10,
            name: 'Basics of SEO',
            level: '20%',
        },
        11: {
            id: 11,
            name: 'Responsive design',
            level: '100%',
        },
        12: {
            id: 12,
            name: 'Sass',
            level: '70%',
        },
        13: {
            id: 13,
            name: 'Clean Code',
            level: '60%',
        },
        14: {
            id: 14,
            name: 'Axios',
            level: '60%',
        },
        15: {
            id: 15,
            name: 'Bootstrap',
            level: '100%',
        },
        16: {
            id: 16,
            name: 'Material UI',
            level: '65%',
        },
    },
    Languages: {
        1: {
            id: 1,
            language: 'English',
            level: '40%'
        },
        2: {
            id: 2,
            language: 'Persian',
            level: '100%'
        },
    },
    tabStatus: resumeTabs.Education
}

const resumeSlice = createSlice({
    name: 'resume',
    initialState,
    reducers: {
        changeTab(state, action) {
            state.tabStatus = action.payload
        }
    }
})

export const {
    changeTab
} = resumeSlice.actions

export default resumeSlice.reducer


export const selectResumeActiveTab = state => state.resume.tabStatus

export const selectResumeItems = state => {
    const { tabStatus } = state.resume
    switch (tabStatus) {
        case 'Education':
            return Object.values(state.resume.Education)
        case 'Work experience':
            return Object.values(state.resume.WorkExperience)
        case 'Skills':
            return Object.values(state.resume.Skills)
        case 'Languages':
            return Object.values(state.resume.Languages)
        default:
            return Object.values(state.resume.Education)

    }
}
