import { createSlice } from '@reduxjs/toolkit'

const category = {
    personal: 'Personal',
    ecommerce: 'Ecommerce',
    corporate: 'Corporate',
    educational: 'Educational',
}

export const portfolioTabs = {
    React: 'React',
    Wordpress: 'Wordpress'
}

const initialState = {
    react: {
        1: {
            id: 1,
            name: 'Personal Website Ali Ahmadi',
            category: category.personal,
            link: 'https://ali-a.ir',
            thumbnail: './ali-a.jpg'
        }
    },
    wordpress: {
        1: {
            id: 1,
            name: 'victorwear.ir',
            category: category.ecommerce,
            link: 'https://victorwear.ir',
            thumbnail: './victorwear.jpg'
        },
        2: {
            id: 2,
            name: 'sageman.pet',
            category: category.ecommerce,
            link: 'https://sageman.pet',
            thumbnail: './sageman.jpg'
        },
        3: {
            id: 3,
            name: 'derakhshansho.com',
            category: category.corporate,
            link: 'https://derakhshansho.com',
            thumbnail: './derakhshansho.jpg'
        },
        4: {
            id: 4,
            name: 'mvmkaraj.com',
            category: category.corporate,
            link: 'https://mvmkaraj.com',
            thumbnail: './mvmkaraj.jpg'
        },
        5: {
            id: 5,
            name: 'cbfiran.com',
            category: category.ecommerce,
            link: 'https://cbfiran.com',
            thumbnail: './cbfiran.jpg'
        },
        7: {
            id: 7,
            name: 'ahmadi-lift.com',
            category: category.corporate,
            link: 'https://ahmadi-lift.com',
            thumbnail: './ahmadi-lift.jpg'
        },
        8: {
            id: 8,
            name: 'altamotorss.com',
            category: category.corporate,
            link: 'https://altamotorss.com',
            thumbnail: './altamotorss.jpg'
        },
        9: {
            id: 9,
            name: 'ktmaerosols.com',
            category: category.corporate,
            link: 'https://ktmaerosols.com',
            thumbnail: './ktmaerosols.jpg'
        },
        10: {
            id: 10,
            name: 'injachi.com',
            category: category.corporate,
            link: 'https://injachi.com',
            thumbnail: './injachi.jpg'
        },
        11: {
            id: 11,
            name: 'dallbazar.com',
            category: category.corporate,
            link: 'https://dallbazar.com',
            thumbnail: './dallbazar.jpg'
        },
        12: {
            id: 12,
            name: 'atibeautysalon.com',
            category: category.ecommerce,
            link: 'https://atibeautysalon.com',
            thumbnail: './atibeautysalon.jpg'
        },
        13: {
            id: 13,
            name: 'clinicayli.com',
            category: category.ecommerce,
            link: 'https://clinicayli.com',
            thumbnail: './clinicayli.jpg'
        },
        14: {
            id: 14,
            name: 'sayehmandegar.com',
            category: category.ecommerce,
            link: 'https://sayehmandegar.com',
            thumbnail: './sayehmandegar.jpg'
        },
        15: {
            id: 15,
            name: 'shireasan.com',
            category: category.ecommerce,
            link: 'https://shireasan.com',
            thumbnail: './shireasan.jpg'
        },
        16: {
            id: 16,
            name: 't1nama.com',
            category: category.corporate,
            link: 'https://t1nama.com',
            thumbnail: './t1nama.jpg'
        },
        17: {
            id: 17,
            name: 'technosheed.com',
            category: category.ecommerce,
            link: 'https://technosheed.com',
            thumbnail: './technosheed.jpg'
        },
        18: {
            id: 18,
            name: 'vigendecor.com',
            category: category.ecommerce,
            link: 'https://vigendecor.com',
            thumbnail: './vigendecor.jpg'
        },
        19: {
            id: 19,
            name: 'shokofeman.com',
            category: category.ecommerce,
            link: 'https://shokofeman.com',
            thumbnail: './shokofeman.jpg'
        }
    },
    tabStatus: portfolioTabs.Wordpress
}

const portfolioSlice = createSlice({
    name: 'portfolios',
    initialState,
    reducers: {
        changeTab(state, action) {
            state.tabStatus = action.payload
        }
    }
})

export const {
    changeTab
} = portfolioSlice.actions

export default portfolioSlice.reducer

export const selectPortfoliosActiveTab = state => state.portfolios.tabStatus

const selectportfoliosByStatus = state => {
    const { tabStatus } = state.portfolios
    if (tabStatus === 'React') {
        return (state.portfolios.react)
    }
    return (state.portfolios.wordpress)
}

export const selectPortfoliosById = state => {
    return selectportfoliosByStatus(state)
}

