import { shallowEqual, useSelector } from 'react-redux'
import { selectResumeItems } from '../../../features/resume/resumeSlice'

export const EducationCard = () => {
    const educationList = useSelector(selectResumeItems, shallowEqual)
    const educationListData = Object.values(educationList).map(data => {
        return (
            < div key={data.id} className="resume-single-list" >
                <div className="inner">
                    <div className="heading">
                        <div className="title">
                            <h4>{`${data.grade} ${data.major}`}</h4>
                            <span>Country: {data.country} - City: {data.city}</span>
                            <span>University: {data.university} </span>
                        </div>
                        <div className="date-of-time">
                            <span>{data.studying ? `entrance ${data.entrance}` : data.entrance}{data.studying ? null : ` - ${data.graduation}`}</span>
                        </div>
                    </div>
                </div>
            </div >
        )
    })

    return educationListData
}

export const WorkExperienceCard = () => {
    const workExperienceList = useSelector(selectResumeItems, shallowEqual)
    const workExperienceListData = Object.values(workExperienceList).map(data => {
        return (
            < div key={data.id} className="resume-single-list" >
                <div className="inner">
                    <div className="heading">
                        <div className="title">
                            <h4>{data.position}</h4>
                            <span>Company Name: {data.companyName}</span>
                            <span>Country: {data.country} - City: {data.city}</span>
                        </div>
                        <div className="date-of-time">
                            <span>
                                {
                                    data.workInThisCompany ?
                                        `${data.startDate}(${data.startMonth}) - Working` :
                                        `${data.startDate}(${data.startMonth})`
                                }
                                {
                                    data.workInThisCompany ?
                                        null :
                                        ` - ${data.endDate}(${data.endMonth})`
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        )
    })

    return workExperienceListData
}

export const SkillsCard = () => {
    const skillsList = useSelector(selectResumeItems, shallowEqual)
    const skillsListData = Object.values(skillsList).map(data => {
        return (
            <div key={data.id} className="progress-charts">
                <h6 className="heading heading-h6">{data.name}</h6>
                <div className="progress">
                    <div className="progress-bar wow fadeInLeft" role="progressbar" style={{ width: data.level, visibility: 'visible', animationDuration: '0.5s', animationDelay: '0.3s', animatioName: 'fadeInLeft' }} aria-valuenow={data.level} aria-valuemax="0" ><span className="percent-label">{data.level}</span></div>
                </div>
            </div>
        )
    })

    return skillsListData
}

export const LanguageCard = () => {
    const languageList = useSelector(selectResumeItems, shallowEqual)
    const languageListData = Object.values(languageList).map(data => {
        return (
            <div key={data.id} className="progress-charts">
                <h6 className="heading heading-h6">{data.language}</h6>
                <div className="progress">
                    <div className="progress-bar wow fadeInLeft" role="progressbar" style={{ width: data.level, visibility: 'visible', animationDuration: '0.5s', animationDelay: '0.3s', animatioName: 'fadeInLeft' }} aria-valuenow={data.level} aria-valuemax="0" ><span className="percent-label">{data.level}</span></div>
                </div>
            </div>
        )
    })

    return languageListData
}
