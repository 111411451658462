import Layout from '../../componens/Layout'
import ProjectsCard from './ProjectsCard'
import { motion } from "framer-motion"
// import { useSelector, shallowEqual } from 'react-redux'
import { newState } from "../../features/projects/projectsSlice"

export default function Portfolios() {

    // const projectItems = useSelector(newState, shallowEqual)

    const renderCards = Object.values(newState.projects).map(id => (
        <ProjectsCard key={id.id} data={id} />
    ))
    return (
        <Layout>
            {console.log(newState.projects)}
            <div
                className="rn-portfolio-area rn-section-gap section-separator"
                id="portfolio"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">
                                    Visit my Projects
                                </span>
                                <h2 className="title">My Projects</h2>
                            </div>
                        </div>
                    </div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
                            {renderCards}
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>

    )
}
