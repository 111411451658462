export default function Tabs({ activeClass, value, handleClick }) {
    return (
        <li
            className="nav-item"
        >
            <button
                onClick={() => handleClick(value)}
                className={`nav-link ${activeClass}`}
                id="react"
                data-bs-toggle="tab"
                role="tab"
                aria-selected="true">
                {value}
            </button>
        </li>
    )
}
