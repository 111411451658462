import Layout from '../../componens/Layout'
import PortfolioCard from './PortfolioCard'
import { motion } from "framer-motion"
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    selectPortfoliosActiveTab,
    selectPortfoliosById,
    portfolioTabs,
    changeTab
} from '../../features/portfolio/portfolioSclice'
import Tabs from '../../componens/Tabs'

export default function Portfolios() {

    const portfoliosActivTab = useSelector(selectPortfoliosActiveTab, shallowEqual)
    const portfoliosCards = useSelector(selectPortfoliosById, shallowEqual)
    const dispatch = useDispatch();

    const handleChangeTab = (status) => {
        dispatch(changeTab(status))
    }

    const renderTabs = Object.keys(portfolioTabs).map((key, index) => {
        const value = portfolioTabs[key]
        const activeClass = value === portfoliosActivTab ? 'active' : ''

        return (
            <Tabs key={index} value={value} activeClass={activeClass} handleClick={handleChangeTab} />
        )
    })
    const renderCards = Object.values(portfoliosCards).map(id => (
        <PortfolioCard key={id.id} data={id} />
    ))
    return (
        <Layout>
            <div
                className="rn-portfolio-area rn-section-gap section-separator"
                id="portfolio"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">
                                    Visit my portfolio
                                </span>
                                <h2 className="title">My Portfolio</h2>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <ul className="rn-nav-list nav nav-tabs" id="myTabs" role="tablist">
                            {renderTabs}
                        </ul>
                    </div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
                            {renderCards}
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>

    )
}
