import Header from "../Header";
import Main from "../Main";
import { motion } from "framer-motion"
export default function Layout({ children }) {

    return (
        <>
            <Header />
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <Main>
                    {children}
                </Main>
            </motion.div>
        </>
    )
}
