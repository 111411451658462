import Layout from "../../componens/Layout";
import { Link } from 'react-router-dom'

export default function Home() {
    return (
        <Layout>
            <section id="home" className="slider-style-5 rn-section-gap pb--50 align-items-center with-particles">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner">
                                <div className="thumbnail gradient-border gradient-animation">
                                    <img id="border" className="gradient-border" src="./assest/logo.jpg" alt="" />
                                </div>
                                <h1>Ali Ahmadi</h1>
                                <span className="cd-headline clip is-full-width">
                                    <span>I am a Developer</span>

                                </span>
                                <div className="button-area">
                                    <Link to='/contact' className="rn-btn"><span>CONTACT ME</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
