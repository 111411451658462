import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Portfolios from "./pages/Portfolios";
import Project from "./pages/Projects";
import Resume from "./pages/Resume";
import Contact from "./pages/Contact";

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/portfolios' element={<Portfolios />} />
      <Route path='/project' element={<Project />} />
      <Route path='/resume' element={<Resume />} />
      <Route path='/contact' element={<Contact />} />
    </Routes>
  )
}
